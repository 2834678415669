<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <p-table #dt [value]="colis_list" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10,25,50,100,250]" [loading]="loading" styleClass="p-datatable-responsive-demo"
                [rowHover]="true" [paginator]="true"
                currentPageReportTemplate="De {first} a {last} des {totalRecords} colis" [filterDelay]="0"
                sortField="created_at" [sortOrder]="-1"
                [globalFilterFields]="['ean','gsm1','gsm2','cod','stat.designation']">
                <ng-template pTemplate="caption">
                    <div class="table-header">
                        Gestionnaire de colis
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Global Search" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Date</th>
                        <th>EAN</th>
                        <th>Client</th>
                        <th>Telephone</th>
                        <th>COD</th>
                        <th>Governorat</th>
                        <th>Statut</th>
                        <th>Tentative</th>

                        <th>Tracking</th>
                    </tr>
                    <tr>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($event.target.value, 'created_at', 'contains')" placeholder="Date"
                                class="p-column-filter">

                        </th>
                        <th>
                            <input pInputText type="text" (input)="dt.filter($event.target.value, 'ean', 'startsWith')"
                                placeholder="Code a barre" class="p-column-filter">
                        </th>
                        <th>
                            <input pInputText type="text" (input)="dt.filter($event.target.value, 'client', 'contains')"
                                placeholder="Client" class="p-column-filter">
                        </th>
                        <th>
                            <input pInputText type="text" (input)="dt.filter($event.target.value, 'gsm1', 'startsWith')"
                                placeholder="Telephone" class="p-column-filter">
                        </th>

                        <th>
                            <input pInputText type="text" (input)="dt.filter($event.target.value, 'cod', 'contains')"
                                placeholder="COD" class="p-column-filter">

                        </th>


                        <th>
                            <p-multiSelect [options]="states" optionLabel="name" placeholder="All"
                                (onChange)="onGovChange($event)" styleClass="p-column-filter">

                            </p-multiSelect>
                        </th>

                        <th>
                            <p-multiSelect [options]="stat" placeholder="All" (onChange)="onStatutChange($event)"
                                styleClass="p-column-filter" optionLabel="designation">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-statut-option">
                                        <span class="p-ml-1">{{option.label}}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </th>
                        <th></th>

                        <th>

                        </th>



                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-colis>
                    <tr>
                        <td>
                            {{colis.created_at | date: 'MMM d,y'}}
                        </td>
                        <td>
                            {{colis.ean}}
                        </td>
                        <td>
                            {{colis.client}}
                        </td>
                        <td>
                            {{colis.gsm1}}
                        </td>

                        <td>
                            {{colis.cod}}
                        </td>

                        <td>
                            {{colis.governorat}}
                        </td>

                        <td>
                            <span class="customer-badge"
                                [style]="'background:'+ stat[+(colis.last_statut)-1].color">{{stat[+(colis.last_statut)-1].designation}}
                            </span>
                        </td>
                        <td style="text-align:center;width:110px">
                            <span class="number-box">{{colis.tentative}}</span>
                        </td>
                        <td>
                            <button type="button" pButton pRipple icon="pi pi-search"
                                (click)="getColisTrack(colis.id)"></button>
                            <button type="button" class="p-button-success" pButton pRipple icon="pi pi-print"
                                (click)="GeneratePdfVouchers(colis)"></button>

                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">Aucun colis trouvé</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-dialog header="Historique Colis" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
                [baseZIndex]="10000" [draggable]="false" [resizable]="false">

                <div *ngIf="colis_track">
                    <p-table [value]="colis_track" styleClass="p-datatable-responsive-demo">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Date</th>
                                <th>Statut</th>
                                <th>Remarque</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-track>
                            <tr>
                                <td>{{track.date | date: 'MMM d,y'}}</td>
                                <td>{{track.statut}}</td>
                                <td><span *ngIf="track.chargement">{{track.chargement}}</span></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

                <ng-template pTemplate="footer">
                    <p-button icon="pi pi-check" (click)="displayModal=false" label="Fermer" styleClass="p-button-text">
                    </p-button>
                </ng-template>
            </p-dialog>
        </div>
    </div>
</div>