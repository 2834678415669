import { Pickup } from './../../../entities/Pickup';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Pdf } from '../../../entities/pdf'
import * as jsPDF from 'jspdf'
import { Colis } from 'src/entities/Colis';
import { DatePipe } from '@angular/common';
import { ColisService } from 'src/app/services/colis.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Statut } from 'src/entities/Statut';

@Component({
  selector: 'app-pickup',
  templateUrl: './pickup.component.html',
  styleUrls: ['./pickup.component.css'],
  providers: [MessageService, ConfirmationService, DatePipe]
})
export class PickupComponent implements OnInit {
  chargementDialog: boolean;
  submitted: boolean;
  pdf = new Pdf();
  @ViewChild('f') form: any;
  list_chargements: Pickup[];
  colis: Colis;
  list_colis: Colis[];
  user_id: number = +localStorage.getItem("User_id");




  constructor(private datePipe: DatePipe, private _colis: ColisService, private breadcrumbService: BreadcrumbService,
    private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this._colis.getAllEnlevementsByUser(this.user_id).subscribe(
      data => {
        this.list_chargements = data;
      })
  }

  getKeyStatut(param) {
    var stat: Statut[];
    stat = JSON.parse(localStorage.getItem("statutsList"));
    for (let element of stat) {
      if (element.designation.toUpperCase() === param.toUpperCase()) {
        return element.id;
      }
    }
  }

  printEnlevement(id) {
    this._colis.getEnlevementColisByid(id).subscribe(data => {
      this.list_colis = data;
      this.pdf.GeneratePdfListColis(this.list_colis)
    })
  }




  async deleteEnlevement(enlevement: Pickup) {
    try {
      const success = await this._colis.Delete(enlevement, 'En Attente');
      if(success=="success"){
        this.list_chargements=this.list_chargements.filter(val => val.id !== enlevement.id);
        this.messageService.add({ severity: 'success', summary: 'Succés', detail: 'Manifest Supprimé avec succés' });

      }      
    }
    catch (error) {
      console.error(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur ! Suppression Manifest' });
    }
  }


}
