import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Client } from 'src/entities/Clients';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const optionRequete = {

};


const BACKEND_DOMAIN = environment.baseUrl;


@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private _http: HttpClient) { }


  getAllClients() {
    let params = new HttpParams().set("token",localStorage.getItem("token")); 
    return this._http.get<Client[]>(this.buildURL('/api/admin/clients','')
    ,{params: params});
  
  }

  getListClients(arr :any[]) {
    return this._http.post<Client[]>(this.buildURL('/api/admin/clients/attente',''),{
      token:localStorage.getItem("token"),
      clients_list:arr.join(',')
    }
    ,optionRequete);
  
  }

    SaveClient(client: Client): any {
      return this._http.post(this.buildURL('/auth/register',''), {
        token:localStorage.getItem("token"),
        name : client.name,
        email : client.email,
        password : client.password,
        gsm : client.gsm,
        gsm2 : client.gsm2,
        adresse : client.adresse,
        governorat : client.governorat,
        frais_livraison : client.frais_livraison,
        frais_retour : client.frais_retour,
        mf : client.mf
    },optionRequete);
  }


  UpdateClient(client: Client):any 
  {
    return this._http.put(this.buildURL('/api/admin/clients/',client.id), {
      token:localStorage.getItem("token"),
      name : client.name,
      email : client.email,
      password : client.password,
      gsm : client.gsm,
      gsm2 : client.gsm2,
      adresse : client.adresse,
      governorat : client.governorat,
      frais_livraison : client.frais_livraison,
      frais_retour : client.frais_retour,
      mf : client.mf
  },optionRequete);

  }

  deleteColis(selectedclient) {
    let params = new HttpParams().set("token",localStorage.getItem("token")); 
    return this._http.delete(this.buildURL('/api/admin/clients/',selectedclient)
    ,{params: params});
  }
  

  info(){
    let params = new HttpParams().set("token", localStorage.getItem("token"));
    return this._http.get<any[]>(this.buildURL('/info','') , { params: params });
  
  }

  
  buildURL(path,user?) {
    return BACKEND_DOMAIN + path+user;
}


}


