<p-toast position="top-right"></p-toast>
<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <h5>Création Rapide</h5>
            <form novalidate (ngSubmit)="creer()" #f="ngForm">

            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="firstname">Nom Complet</label>
                    <input id="client" type="text" pInputText [(ngModel)]="colis.client" name="client" required>
                </div>
                    <div class="p-field p-col-12 p-md-4">
                    <label for="state">Governorat</label>
                    <p-dropdown name="gov" optionLabel="name"  [options]="states" [(ngModel)]="selectedState" placeholder="Select" ></p-dropdown>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="telephone">Telephone</label>
                    <p-inputNumber  mode="decimal" minlength="8" maxlength="8" (focusout)="VerifyNumber()" [(ngModel)]="colis.gsm1" inputId="telephone"  name="telephone" [useGrouping]="false" required>
                    </p-inputNumber>
                </div>

                <div class="p-field p-col-12 p-md-2">
                    <label for="telephone2">Telephone 2</label>
                    <p-inputNumber  mode="decimal" minlength="8" maxlength="8"  [(ngModel)]="colis.gsm2" inputId="telephone2"  name="telephone2" [useGrouping]="false">
                    </p-inputNumber>
                </div>



                <div class="p-field p-col-12 p-md-8">
                    <label for="adresse">Adresse</label>
                    <input id="adresse" type="text" pInputText [(ngModel)]="colis.adresse" name="adresse" required>
                </div>

                <div class="p-field p-col-12 p-md-4">
                    <label for="adresse">COD (Cash on delivery)</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-dollar"></i>
                        </span>
                            <input type="text" id="inputgroup" pInputText [(ngModel)]="colis.cod" name="cod" required> 
                    </div>
                </div>
                
                <div class="p-field p-col-12 p-md-3">
                    <label for="designation">Designation</label>
                    <input id="designation" type="text" pInputText name="designation" [(ngModel)]="colis.designation">
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label for="nbre">Nombre</label>
                    <input id="nbre" type="text" pInputText name="nbre" [(ngModel)]="colis.nbre">
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <h6>Echange</h6>
                    <p-inputSwitch name="echange" [(ngModel)]="colis.echange"></p-inputSwitch>
                </div>

                
                <div class="p-field p-col-12 p-md-2">
                    <h6>Fragile</h6>
                    <p-inputSwitch name="fragile" [(ngModel)]="colis.fragile"></p-inputSwitch>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <h6>Décharge</h6>
                    <p-inputSwitch name="decharge" [(ngModel)]="colis.decharge"></p-inputSwitch>
                </div>

                <div class="p-field p-col-12 p-md-12">
                    <label for="remarque">Remarque</label>
                    <input id="remarque" name="remarque" type="text" pInputText [(ngModel)]="colis.remarque">
                </div>

            </div>
           
            <div class="buttons" style="text-align: center;">
                <button pButton [disabled]="f.invalid" type="submit" label="Ajouter ce colis" icon="pi pi-check" class="p-button-success" styleClass="p-button-lg"></button>
            </div>
        </form>
        </div>

        <div class="card">
            <h5>Historique Client</h5>
            <p-table [value]="HistoriqueGsm">
                <ng-template pTemplate="header">
                    <tr>
                        <th>NOM</th>
                        <th>GSM </th>
                        <th>GOVERNORAT</th>
                        <th>COD</th>
                        <th>TENTATIVE</th>
                        <th>STATUT</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-colis>
                    <tr>
                        <td>{{colis.client}}</td>
                        <td>{{colis.gsm1}}</td>
                        <td>{{colis.governorat}}</td>
                        <td>{{colis.cod}}
                        <td>{{colis.tentative}}
                        <td> <span class="customer-badge"
                        [style]="'background:'+ stat[+(colis.last_statut)-1].color">{{stat[+(colis.last_statut)-1].designation}}</span>
                    </td>
                    
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td><span class="table-message">Aucun colis en cours</span></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
</div>