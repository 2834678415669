<div class="card">
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <h5>Gestionnaire des colis</h5>
    </ng-template>
    <ng-template pTemplate="right">
      <button
        pButton
        pRipple
        icon="pi pi-plus"
        class="p-button-rounded p-button-success p-mr-3"
        [routerLink]="'/dash/unitaire'"
      ></button>
      <button
        pButton
        type="button"
        icon="pi pi-search"
        (click)="openSearch()"
        class="p-button-rounded p-button-success p-mr-3"
      ></button>
      <button
        pButton
        pRipple
        icon="pi pi-file-excel"
        class="p-button-rounded p-button-success"
        (click)="exportExcel()"
      ></button>
    </ng-template>
  </p-toolbar>

  <p-table
    #dt
    [value]="colis_list"
    dataKey="id"
    [rows]="25"
    [showCurrentPageReport]="true"
    class="p-col-12 p-md-12 p-lg-12"
    responsiveLayout="scroll"
    [rowHover]="true"
    [paginator]="true"
    [filterDelay]="0"
    sortField="created_at"
    [sortOrder]="-1"
    [scrollable]="true"
    scrollHeight="510px"
    [rowsPerPageOptions]="[25, 50, 100, 500, 1000]"
    [totalRecords]="totalRecords"
    [lazy]="true"
    (onLazyLoad)="onPageChange($event)"
    currentPageReportTemplate="Showing {first} to {last} of {{
      totalRecords
    }} entries"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="min-width: 100px" pSortableColumn="created_at">
          Date <p-sortIcon field="created_at"></p-sortIcon>
        </th>
        <th style="min-width: 150px">EAN</th>
        <th style="min-width: 150px">Client</th>
        <th style="min-width: 150px">Telephone</th>
        <th style="min-width: 100px">COD</th>
        <th style="min-width: 100px">Governorat</th>
        <th style="min-width: 200px">Designation</th>
        <th style="min-width: 60px">Tent.</th>
        <th style="min-width: 100px">Statut</th>
        <th style="min-width: 100px">Tracking</th>
      </tr>
      <tr>
        <th style="min-width: 100px"></th>
        <th style="min-width: 150px">
          <input
            pInputText
            type="text"
            (input)="filterAll($event.target.value, 'ean')"
            placeholder="Code a barre"
            styleClass="p-column-filter"
          />
        </th>

        <th style="min-width: 150px">
          <input
            pInputText
            type="text"
            (input)="filterAll($event.target.value, 'client')"
            placeholder="Client"
            styleClass="p-column-filter"
          />
        </th>
        <th style="min-width: 150px">
          <input
            pInputText
            type="text"
            (input)="filterAll($event.target.value, 'gsm1')"
            placeholder="Telephone"
            style="width: 5em"
            styleClass="p-column-filter"
          />
        </th>

        <th style="min-width: 100px">
          <input
            pInputText
            type="text"
            (input)="filterAll($event.target.value, 'cod')"
            placeholder="COD"
            style="width: 5em"
            styleClass="p-column-filter"
          />
        </th>

        <th style="min-width: 100px">
          <p-multiSelect
            optionLabel="name"
            [options]="states"
            placeholder="All"
            (onChange)="onGovChange($event)"
            styleClass="p-column-filter"
          >
          </p-multiSelect>
        </th>
        <th style="min-width: 200px">
          <input
            pInputText
            type="text"
            (input)="filterAll($event.target.value, 'designation')"
            placeholder="DESIGNATION"
            styleClass="p-column-filter"
          />
        </th>

        <th style="min-width: 60px"></th>
        <th style="min-width: 100px">
          <p-multiSelect
            [options]="stat"
            placeholder="All"
            (onChange)="onStatutChange($event)"
            styleClass="p-column-filter"
            optionLabel="designation"
          >
          </p-multiSelect>
        </th>
        <th style="min-width: 100px"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-colis let-columns="columns">
      <ngx-skeleton-loader
        *ngIf="skeleton"
        animation="progress"
      ></ngx-skeleton-loader>

      <tr *ngIf="!skeleton">
        <td style="min-width: 100px">
          {{ colis.created_at | date : "MMM d,y" }}
        </td>
        <td style="min-width: 150px">
          {{ colis.ean }}
        </td>
        <td style="min-width: 150px">
          {{ colis.client }}
        </td>
        <td style="min-width: 150px">
          {{ colis.gsm1 }}
        </td>

        <td style="min-width: 100px">
          {{ colis.cod }}
        </td>

        <td style="min-width: 100px">
          {{ colis.governorat }}
        </td>
        <td style="min-width: 200px">
          {{ colis.designation }}
        </td>
        <td style="min-width: 60px">
          {{ colis.tentative }}
        </td>
        <td style="min-width: 100px">
          <span
            class="customer-badge"
            [style]="'background:' + stat[+colis.last_statut - 1].color"
            >{{ stat[+colis.last_statut - 1].designation }}
          </span>
        </td>
        <td style="min-width: 100px">
          <button
            type="button"
            class="p-button-rounded"
            pButton
            pRipple
            icon="pi pi-search"
            (click)="getColisTrack(colis.id)"
          ></button>
          <button
            type="button"
            class="p-button-rounded p-button-success"
            pButton
            pRipple
            icon="pi pi-print"
            (click)="GeneratePdfVouchers(colis)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <ngx-skeleton-loader
        count="15"
        *ngIf="skeleton"
        animation="progress"
      ></ngx-skeleton-loader>
    </ng-template>
  </p-table>
</div>

<p-dialog
  header="Historique Colis"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div *ngIf="colis_track">
    <p-table [value]="colis_track">
      <ng-template pTemplate="header">
        <tr>
          <th>Date</th>
          <th>Statut</th>
          <th>Nom & Num Livreur</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-track>
        <tr>
          <td>{{ track.date | date : "MMM d,y" }}</td>
          <td>{{ track.statut }} <span *ngIf="track.chargement ">   - <b>{{ track.chargement }}</b></span> </td>
          <td>{{ track.nom  }} - {{ track.gsm1 }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayModal = false"
      label="Fermer"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="dialogSearch"
  [style]="{ width: '600px' }"
  (onHide)="hideDialog()"
  header="Recherche Commande"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6">
        <label for="dateDebut">Date de début </label>
        <input
          type="datetime-local"
          [(ngModel)]="dateDebut"
          name="dateDebut"
          pInputText
        />
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label for="dateFin">Date de fin</label>
        <input
          type="datetime-local"
          [(ngModel)]="dateFin"
          name="dateFin"
          pInputText
        />
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6 p-md-6">
        <button
          pButton
          label="Search"
          (click)="search()"
          icon="pi pi-check"
          class="p-button-success p-md-2"
        ></button>
      </div>
      <div class="p-field p-col-6 p-md-6">
        <button
          pButton
          label="Clear"
          (click)="clearSearch()"
          icon="pi pi-check"
          class="p-button-warning p-md-2"
        ></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
  </ng-template>
</p-dialog>
