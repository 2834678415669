import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Colis } from 'src/entities/Colis';
import { BreadcrumbService } from '../../breadcrumb.service';
import {ColisService} from '../../services/colis.service'
import {Pdf} from '../../../entities/pdf'
import * as jsPDF from 'jspdf'
import { Statut } from 'src/entities/Statut';
import { AppConstants } from 'src/app/AppConstants';
import { Pickup } from 'src/entities/Pickup';


@Component({
  selector: 'app-attente',
  templateUrl: './attente.component.html',
  styleUrls: ['./attente.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class AttenteComponent implements OnInit {
  
  colisDialog: boolean;
    pdf = new Pdf();
    public colis_list: Colis[];
    colis: Colis;
    stat:Statut[]=JSON.parse(localStorage.getItem("statutsList"));
    selectedColis: Colis[];
    submitted: boolean;
    cols: any[];
    selectedState: any = null;
    states=AppConstants.governorats;
    enlevement:Pickup=new Pickup;


  constructor(private _auth: ColisService,private messageService: MessageService,
    private confirmationService: ConfirmationService, private breadcrumbService: BreadcrumbService) 
    { 
      this.breadcrumbService.setItems([
        { label: 'Colis' },
        { label: 'En Attente', routerLink: ['/dash/attente'] }
    ]);
    }

  ngOnInit(){
    
    this._auth.getAttente().subscribe(data => {
      this.colis_list =data.filter(val => val.imprimer == 0);
      })

    this.cols = [
      { field: 'created_at ', header: 'Date' },
      { field: 'ean', header: 'EAN' },
      { field: 'client', header: 'Nom' },
      { field: 'gsm1', header: 'Telephone' },
      { field: 'cod', header: 'Montant' },
      { field: 'adresse', header: 'Adresse' },
      { field: 'governorat', header: 'Governorat'},
      { field: 'last_statut', header: 'Statut'},

  ];
  }

  hideDialog() {
    this.colisDialog = false;
  }
  deleteSelectedColis(colis: Colis) {
    this.confirmationService.confirm({
        message: 'Vous êtes sure de supprimer ?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
        this._auth.deleteColis(colis.id).subscribe(
          (jsonData:string) => {
            this.messageService.add({severity: 'success', summary: 'Successful', detail: jsonData, life: 3000});
            this.colis_list = this.colis_list.filter(val => val.id !== colis.id);
          },
          (err) =>  this.messageService.add({severity: 'error', summary: 'Erreur', detail: err, life: 3000})
        );
        }
    });
}


updateSelectedColis(colis: Colis) {
  this.colis=colis;
  this.colisDialog = true;
}

async updateColis() {
    try {
      const success = await this._auth.Update(this.colis);
      this.messageService.add({severity:'success', summary: 'Success', detail: 'Votre Colis a étè modifié avec succés'});
      this.hideDialog();
    } catch (error) {
      console.error(error);
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Erreur ! Verifier vos informations'});
    }

}


GeneratePdfVouchers() {
this.pdf.GeneratePdfVoucher(this.selectedColis);
this.selectedColis.forEach(element => {
  const success = this._auth.SetImprimer(element.id);

    this.colis_list = this.colis_list.reduce((p,c) => (c.ean !== element.ean && p.push(c),p),[]);

});
}



async GeneratePdfList(){
  var colis_list:any[]=[];
  var arr:any[]=[];

  this.selectedColis.map(
    (colis)=>{
      colis_list.push(colis.ean)
      arr.push(colis.id);
    }
  )

  try {

var selected_user=+localStorage.getItem("User_id");
this.enlevement.reference= "success";
  this.enlevement.user_id=selected_user;
  this.enlevement.type='pickup';
  this.enlevement.livreur_id=1;
  this.enlevement.total_colis=arr.length;
  this.enlevement.list_colis=arr.join(',');
  try {
    const success = await this._auth.AddTopickup(this.enlevement,'Enleve');

    this.selectedColis.map(
      (colis)=>{
        this.colis_list = this.colis_list.filter(val => val.id !== colis.id);
      }
    )
    this.selectedColis=[];
    this.messageService.add({severity:'success', summary: 'Success', detail: 'Enlevement créer avec succés'});
  } catch (error) {
    console.error(error);
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Erreur ! Verifier vos informations'});
  }

} catch (error) {
  console.error(error);
  this.messageService.add({severity:'error', summary: 'Error', detail: 'Erreur ! Verifier vos colis'});
}



}





}
