import { Component, OnInit,ViewChild } from '@angular/core';
import { BreadcrumbService } from '../../breadcrumb.service';
import {ColisService} from '../../services/colis.service'
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { Recu } from 'src/entities/Recu';
import { Colis } from 'src/entities/Colis';
import { PaiementsService } from 'src/app/services/paiements.service';
import { forkJoin } from 'rxjs';
import {Pdf} from '../../../entities/pdf'
import * as jsPDF from 'jspdf'
import { Statut } from 'src/entities/Statut';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-consultation-paiement',
  templateUrl: './consultation-paiement.component.html',
  styleUrls: ['./consultation-paiement.component.css']
})
export class ConsultationPaiementComponent implements OnInit {

  List_recu:Recu[]=[];
  List_colis:Colis[]=[];
  pdf = new Pdf();
  loading: boolean = true;
  
    @ViewChild('dt') table: Table;
  
    constructor(private _paiements: PaiementsService,
                private _colis: ColisService,
                private breadcrumbService: BreadcrumbService) { }
  
    ngOnInit(): void {
     this._paiements.getAllRecuByuser().subscribe( 
       data=> {
        this.List_recu=data;
        this.loading = false;
       }
     )
      
    }

  exportExcel(recu:Recu) {
    const requests = this._colis.getAllColisByRecu(recu).subscribe(
      (data) => {
        var xlsData:any;
        const xlsArray = data.map(o => {
          return { ean: o.ean,client: o.client,gsm: o.gsm1, governorat: o.governorat,cod: o.cod,statut:this.getTextStatut(o.last_statut) };
        });
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(xlsArray);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "colis_livre");
      });
      });
    
  }
  
  getTextStatut(param) {
    var stat:Statut[];
    stat=JSON.parse(localStorage.getItem("statutsList"));
    for(let element of stat){
      if (element.id === param) {
      return element.designation;
      }  
    }
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  
  GeneratePdfList(recu:Recu){
    var client:any=JSON.parse(localStorage.getItem('User_informations'))
      console.log('object',  JSON.parse(localStorage.getItem("info"))      );
  const requests = this._colis.getAllColisByRecu(recu).subscribe(
            (data) => {
              this.pdf.GeneratePdfListColisPaiement(data,recu,client);
            }, 
            );
  }


}
