
export class Colis {
    public id:number;
    public old_ean:number;
    public ean: number;
    public user_id: number;
    public cod: number;
    public livreur:number;
    public client: string;
    public adresse: string;
    public governorat: string;
    public gsm1: number;
    public gsm2: string;
    public designation: string;
    public nbre: number;
    public last_statut:number;
    public remarque: string;
    public echange: boolean;
    public tentative:number;
    public imprimer:number;
    public multiple:boolean=false;
    public decharge: boolean;
    public fragile: boolean;
    public last_depot:number;
    public created_at:string
    
    constructor(
        id?,
        old_ean?,
        ean?,
        user_id?, 
        cod?,
        livreur?,
        client?,
        adresse?, 
        governorat?,
        gsm1?,
        gsm2?, 
        last_depot?,
        nbre?,
        remarque?, 
        tentative?,
        imprimer?,
        multiple?,
        echange?,
        decharge?,
        fragile?,
        last_statut?,
        created_at?
         ) {
         this.id = id;  
        this.ean = ean;
        this.old_ean = old_ean ;
        this.cod = cod;
        this.livreur = livreur;
        this.user_id = user_id;
        this.client = client;
        this.adresse = adresse;
        this.governorat = governorat;
        this.gsm1 = gsm1;
        this.gsm2 = gsm2;
        this.last_depot = last_depot;
        this.nbre = nbre;
        this.remarque = remarque;
        this.tentative=tentative;
        this.imprimer=imprimer;
        this.multiple=multiple;
        this.echange = echange;
        this.decharge = decharge;
        this.fragile = fragile;
        this.last_statut=last_statut;
        this.created_at=created_at;

    }
}