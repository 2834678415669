<p-toast></p-toast>

<div class="card">

    <p-table #dt [value]="list_chargements" sortField="created_at" [sortOrder]="-1" [rows]="10" [paginator]="true"
        [globalFilterFields]="['created_at','depot_id','governorat','reference','type','livreur_id']"  [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">

            <div class="p-d-flex p-ai-center p-jc-between">
                <h5 class="p-m-0">Historique des enlevements</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Recherche..." />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="created_at">Date <p-sortIcon field="created_at"></p-sortIcon>
                </th>
                <th>Reference</th>
                <th>Total Colis</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-chargement>
            <tr>
                <td> {{chargement.created_at | date: 'MMM d,y'}}</td>
                <td>{{chargement.reference}}</td>
                <td>{{chargement.total_colis}}</td>
                <td>
                    <button pButton pRipple icon="pi pi-check pi-eye" label="Imprimer BR" style="margin: 0 5px;"
                        class="p-button-success" (click)="printEnlevement(chargement.id)"></button>

                    <button pButton pRipple icon="pi pi-check pi-eye" label="Supprimer BR" style="margin: 0 5px;"
                        class="p-button-danger" (click)="deleteEnlevement(chargement)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                {{list_chargements ? list_chargements.length : 0 }} Total des Chargements.
            </div>
        </ng-template>
    </p-table>
</div>


<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
