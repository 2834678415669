export class Statut {
    public id: string;
    public designation: string;
    public color: string;
    public count:number ;
    public total:number;


    constructor(id?, designation?, color?,count?,total?) {
        this.id = id;
        this.designation = designation;
        this.color = color;
        this.count=count;
        this.total=total;

    }
}