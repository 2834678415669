export class User {
    public id: number;
    public name: string;
    public email: string;
    public password: string;
    public gsm: string;
    public gsm2: string;
    public adresse: string;
    public governorat: string;
    public frais_livraison: string;
    public frais_retour: string;
    public mf: string;
    

    constructor(id?,name?, 
        email?,
        password?,
        gsm?, 
        gsm2?,
        adresse?,
        governorat?,
        frais_livraison?, 
        frais_retour?,
        mf?) 
        {
         this.id = id;
        this.name = name;
        this.email = email;
        this.password = password;
        this.gsm = gsm;
        this.gsm2 = gsm2;
        this.adresse = adresse;
        this.governorat = governorat;
        this.frais_livraison = frais_livraison;
        this.frais_retour = frais_retour;
        this.mf = mf;
    }





    
}