<div class="p-grid">
    <div class="p-col-12">

        <p-toast></p-toast>

        <div class="card">
            <p-toolbar class="p-d-md-none p-fluid p-formgrid p-grid  p-mb-"  [style]="{'margin-left': '6px'}">
                <button pButton pRipple icon="pi pi-arrow-down" class="p-button-success p-mr-2 "
                    (click)="GeneratePdfList()" label="Manifest" [disabled]="!selectedColis|| !selectedColis.length"></button>
                <button pButton pRipple icon="pi pi-file-pdf" label="Vouchers" class="p-button-primary" (click)="GeneratePdfVouchers()"
                    [disabled]="!selectedColis|| !selectedColis.length"></button>
            </p-toolbar>

            <p-toolbar styleClass="p-mb-4" >
                
                <ng-template pTemplate="left">
                    <div class="p-d-none p-d-md-inline-flex">
                        <button pButton pRipple label="Génerer Manifest" icon="pi pi-file-pdf"
                            class="p-button-success p-mr-2"  [disabled]="!selectedColis|| !selectedColis.length" (click)="GeneratePdfList()"></button>
                        <button pButton pRipple label="Imprimer" icon="pi pi-file-pdf" class="p-button-success"
                            (click)="GeneratePdfVouchers()"
                            [disabled]="!selectedColis|| !selectedColis.length"></button>
                        </div>
                </ng-template>


                <ng-template pTemplate="right">
                    <div class="p-d-none p-d-md-inline-flex">

                    </div>
                </ng-template>
                
            
            </p-toolbar>
 

            <p-table #dt [value]="colis_list" [columns]="cols" [rows]="10" [paginator]="true"
                styleClass="p-datatable-responsive-demo"
                [globalFilterFields]="['ean','client','gsm1','gsm2','governorat']" [(selection)]="selectedColis"
                [rowsPerPageOptions]="[10,25,50,100,250,500,1000,2000]" [rowHover]="true" dataKey="id"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <h5 class="p-m-0">Colis en Attente</h5>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Recherche..." style="max-width: 170px;" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="created_at">Date <p-sortIcon field="created_at"></p-sortIcon>
                        </th>
                        <th pSortableColumn="ean">Ean <p-sortIcon field="ean"></p-sortIcon>
                        </th>
                        <th pSortableColumn="client">Client <p-sortIcon field="client"></p-sortIcon>
                        </th>
                        <th pSortableColumn="gsm1">Telephone <p-sortIcon field="gsm1"></p-sortIcon>
                        </th>
                        <th pSortableColumn="adresse">Adresse <p-sortIcon field="adresse"></p-sortIcon>
                        </th>
                        <th pSortableColumn="cod">COD <p-sortIcon field="cod"></p-sortIcon>
                        </th>
                        <th pSortableColumn="governorat">Governorat <p-sortIcon field="governorat"></p-sortIcon>
                        </th>
                        <th pSortableColumn="last_statut">Statut <p-sortIcon field="last_statut"></p-sortIcon>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-colis>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="colis"></p-tableCheckbox>
                        </td>
                        <td>{{colis.created_at | date: 'MMM d,y'}}</td>
                        <td>{{colis.ean}}</td>
                        <td>{{colis.client}}</td>
                        <td>{{colis.gsm1}}</td>
                        <td>{{colis.adresse}}</td>
                        <td>{{colis.cod }}</td>
                        <td>{{colis.governorat}}</td>
                        <td>{{stat[+(colis.last_statut)-1].designation}} </td>
                        <td>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                (click)="deleteSelectedColis(colis)"></button>
                          <!--  <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success"
                                (click)="updateSelectedColis(colis)"></button>-->

                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Vous avez {{colis_list ? colis_list.length : 0 }} colis en attente.
                    </div>
                </ng-template>
            </p-table>
        </div>

        <p-dialog *ngIf="colis" [(visible)]="colisDialog" [style]="{width: '850px'}" header="Informations Colis"
            [modal]="true" styleClass="p-fluid">
            <ng-template pTemplate="content">
                <form novalidate (ngSubmit)="updateColis()" #f="ngForm">

                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-4">
                            <label for="firstname">Nom Complet</label>
                            <input id="client" type="text" pInputText [(ngModel)]="colis.client" name="client" required>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <label for="telephone">Telephone</label>
                            <p-inputNumber mode="decimal" [(ngModel)]="colis.gsm1" inputId="telephone" name="telephone"
                                [useGrouping]="false" required>
                            </p-inputNumber>
                        </div>

                        <div class="p-field p-col-12 p-md-4">
                            <label for="telephone2">Telephone 2</label>
                            <p-inputNumber mode="decimal" [(ngModel)]="colis.gsm2" inputId="telephone2"
                                name="telephone2" [useGrouping]="false">
                            </p-inputNumber>
                        </div>
                        <div class="p-field p-col-12 p-md-8">
                            <label for="adresse">Adresse</label>
                            <input id="adresse" type="text" pInputText [(ngModel)]="colis.adresse" name="adresse"
                                required>
                        </div>

                        <div class="p-field p-col-12 p-md-4">
                            <label for="adresse">COD (Cash on delivery)</label>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-dollar"></i>
                                </span>
                                <input type="text" id="inputgroup" pInputText [(ngModel)]="colis.cod" name="cod"
                                    required>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="designation">Designation</label>
                            <input id="designation" type="text" pInputText name="designation"
                                [(ngModel)]="colis.designation">
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="nbre">Nombre</label>
                            <input id="nbre" type="text" pInputText name="nbre" [(ngModel)]="colis.nbre">
                        </div>
                        <div class="p-field p-col-12 p-md-2">
                            <h6>Echange</h6>
                            <p-inputSwitch name="echange" [(ngModel)]="colis.echange"></p-inputSwitch>
                        </div>

                        <div class="p-field p-col-12 p-md-2">
                            <h6>Décharge</h6>
                            <p-inputSwitch name="decharge" [(ngModel)]="colis.decharge"></p-inputSwitch>
                        </div>

                        <div class="p-field p-col-12 p-md-12">
                            <label for="remarque">Remarque</label>
                            <input id="remarque" name="remarque" type="text" pInputText [(ngModel)]="colis.remarque">
                        </div>

                    </div>

                    <div class="buttons" style="text-align: center;">
                        <button pButton [disabled]="f.invalid" type="submit" label="Modifier le Colis"
                            icon="pi pi-check" class="p-button-success" styleClass="p-button-lg"></button>
                    </div>
                </form>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                    (click)="hideDialog()"></button>
            </ng-template>
        </p-dialog>

        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

    </div>
</div>