<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">

    <div class="login-content">

        <div class="login-panel">
            <div class="login-panel-content">
                <div class="logo">
                </div>
                <h1>{{APPNAME}}</h1>
                <p>Espace Client</p>
                <div class="forms">
                    <div class="login-input-wrapper">
                        <label for="username">email</label>
                        <input id="email" pInputText placeholder="Type your email..." [(ngModel)]="model.email" required />
                        <i class="pi pi-user"></i>
                    </div>

                    <div class="login-input-wrapper">
                        <label for="password">Mot de Passe</label>
                        <input type="password" id="password" placeholder="Type your password" [(ngModel)]="model.password" pPassword [feedback]="false" required/>
                        <i class="pi pi-lock"></i>
                    </div>

                    <div class="p-col-12">
                        <p-checkbox [(ngModel)]="checked" label="Se souvenir de moi" value="SaveInfo" binary="true"></p-checkbox>
                    </div>


                    <div class="buttons">
                        <button pButton type="button" (click)="login()" label="Login"></button>
                        <a href="#" class="secondary-button">Mot de passe oublié</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="desert"></div>
    </div>
</div>
