import { Component, OnInit,ViewChild } from '@angular/core';
import { Colis } from 'src/entities/Colis';
import { BreadcrumbService } from '../../breadcrumb.service';
import {ColisService} from '../../services/colis.service'
import {ClientsService} from '../../services/clients.service'
import { Table } from 'primeng/table';
import { Statut } from 'src/entities/Statut';
import { PrimeNGConfig } from 'primeng/api';import { SelectItem } from 'primeng/api';
import { Client } from 'src/entities/Clients';
import {Pdf} from '../../../entities/pdf'
import * as jsPDF from 'jspdf'
import { DatePipe } from '@angular/common';
import { AppConstants } from 'src/app/AppConstants';
@Component({
  selector: 'app-archive-colis',
  templateUrl: './archive-colis.component.html',
  styleUrls: ['./archive-colis.component.scss'],
  providers: [DatePipe]
})
export class ArchiveColisComponent implements OnInit {
  stat:Statut[]=JSON.parse(localStorage.getItem("statutsList"));
  pdf = new Pdf();
  colis_list: Colis[];
  user_list:Client[]=[];
  filteredColis:Colis[]=[];
  user_list_array:Client[]=[];
  governotat: any[];
  loading: boolean = true;
  colis_track:any=[];
  displayModal: boolean;
  states=AppConstants.governorats;

  @ViewChild('dt') table: Table;


  constructor(private datePipe: DatePipe,private _client: ClientsService,private _colis: ColisService,private breadcrumbService: BreadcrumbService) { }


ngOnInit(): void {
  
  this._client.getAllClients().subscribe(   
    data => {
      this.user_list=data;
      this._colis.getAllArchiveColis().subscribe(   
        colis => {
          this.colis_list = colis
          this.loading = false;
        })
    })
   
  }

onDateSelect(value) {
    this.table.filter(this.formatDate(value), 'date', 'equals')
}
formatDate(date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
      month = '0' + month;
  }
  if (day < 10) {
      day = '0' + day;
  }
  return date.getFullYear() + '-' + month + '-' + day;
}

onStatutChange(event) {
  var search:any[]=[];
  event.value.forEach(element => {
    search.push(element.id);
  });
  this.table.filter(search, 'last_statut', 'in')
}

onGovChange(event) {
  var search:any[]=[];
  event.value.forEach(element => {
    search.push(element.name);
  });
  this.table.filter(search, 'governorat', 'in')
}

onUserChange(event) {
  var search:any[]=[];
  event.value.forEach(element => {
    search.push(element.id);
  });
  this.table.filter(search, 'user_id', 'in') 
}

getColisTrack(colis){
  this.colis_track=[];
  this._colis.getColisTrackArchive(colis).subscribe(   
    (response: any) => {
      response.forEach(element => {
        var track:any=[];
        track['statut']=this.stat[(+element.statut_id-1)].designation;
        track['date']=element.created_at;
        this.colis_track.push(track);
      });
      this.displayModal=true;
});
}

GeneratePdfVouchers(colis:Colis) {
  var client =this.user_list.find(x => x.id === colis.user_id);
  // this.pdf.GeneratePdfVoucher(colis );
  }

showModalDialog() {
  this.displayModal = true;
}

onTableFiltered(event,data:any){
 this.filteredColis=data;
}

exportExcel() {
  if(this.filteredColis.length>0){
    var colis=this.filteredColis
  } else {
    var colis=this.colis_list
  }
  import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(colis);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "products");
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}


}
