import { style } from "@angular/animations";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Colis } from "src/entities/Colis";
import { BreadcrumbService } from "../../breadcrumb.service";
import { ColisService } from "../../services/colis.service";
import { Table } from "primeng/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Statut } from "src/entities/Statut";
import { PrimeNGConfig } from "primeng/api";
import { SelectItem } from "primeng/api";
import { Pdf } from "../../../entities/pdf";
import * as jsPDF from "jspdf";
import { AppConstants } from "src/app/AppConstants";
import { DatePipe } from "@angular/common";
import { saveAs } from "file-saver";

@Component({
  selector: "app-statistique",
  templateUrl: "./statistique.component.html",
  styleUrls: ["./statistique.component.scss"],
  providers: [DatePipe],
})
export class StatistiqueComponent implements OnInit {
  stat: Statut[] = JSON.parse(localStorage.getItem("statutsList"));
  colis_list: Colis[];
  governotat: any[];
  loading: boolean = true;
  pdf = new Pdf();
  colis_track: any = [];
  displayModal: boolean;
  states = AppConstants.governorats;
  transporteur_list = JSON.parse(localStorage.getItem("livreurDispatch"));

  filteredColis: Colis[] = [];
  @ViewChild("dt") table: Table;
  statByid: number;
  cod: string = "";
  client: string = "";
  id: string = "";
  governorat: any[] = [];
  adresse: string = "";
  gsm1: string = "";
  gsm2: string = "";
  remarque: string = "";
  ean: string = "";
  echange: string = "";
  totalRecords: number;
  statuts: any[] = [];
  dialogSearch: boolean;
  EventRows: number = 25;
  dateDebut: string = "";
  dateFin: string = "";
  skeleton: boolean = true;

  constructor(
    private _auth: ColisService,
    private breadcrumbService: BreadcrumbService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.skeleton = true;
    this.ssetTimeOut(1);
  }
  ssetTimeOut(PageNumber) {
    setTimeout(() => {
      this._auth
        .getAllColis(
          this.EventRows,
          PageNumber,
          this.cod,
          this.client,
          this.id,
          this.governorat,
          this.adresse,
          this.gsm1,
          this.gsm2,
          this.remarque,
          this.ean,
          this.dateDebut,
          this.dateFin,
          this.echange,
          this.statuts
        )
        .subscribe((data) => {
          this.colis_list = data["data"];
          this.totalRecords = data["total"];
          this.skeleton = false;
        });
    }, 1);
  }

  onDateSelect(value) {
    this.table.filter(this.formatDate(value), "date", "equals");
  }

  search() {
    if (this.dateDebut && this.dateFin) {
      this.myFunction(this.dateDebut);
      this.myFunction(this.dateFin);
    }

    this.ssetTimeOut(1);
    this.dialogSearch = false;
  }
  exportExcel() {
    if (this.filteredColis.length > 0) {
      var colis = this.filteredColis;
    } else {
      var colis = this.colis_list;
    }
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(colis);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "products");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  getColor(x, data: any) {
    let statransport = this.stat.find((item) => item.id == data.last_statut);
    x.style.background = statransport.color;
  }
  hideDialog() {
    this.dialogSearch = false;
  }

  openSearch() {
    this.dialogSearch = true;
  }
  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    return date.getFullYear() + "-" + month + "-" + day;
  }

  onGovChange(event) {
    this.governorat = event.value.map((it) => {
      return it;
    });
    this.ssetTimeOut(1);
  }
  clearSearch() {
    this.dateDebut = <string>{};
    this.dateFin = <string>{};

    this.ssetTimeOut(1);
    this.dialogSearch = false;
  }
  myFunction(date) {
    date = new Date();
    let latest_date = this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
  }

  getColisTrack(colis) {
    this.colis_track = [];
    this._auth.getColisTrack(colis).subscribe((response: any) => {
      response.forEach((element) => {
        var track: any = [];
        track["statut"] = this.stat[+element.statut_id - 1].designation;
        track["date"] = element.created_at;
        if (element.motifs != "NULL") {
          track["chargement"] = element.motifs;
        }
        if(element.chauffeurs != "NULL" && element.livreur_id != "NULL"){
          track["nom"]=element.chauffeurs?.nom;
          track["gsm1"]=element.chauffeurs?.gsm1;
        }
        this.colis_track.push(track);
      });
      this.displayModal = true;
    });
  }

  showModalDialog() {
    this.displayModal = true;
  }

  GeneratePdfVouchers(colis: Colis) {
    var selectedColis: Colis[] = [];
    selectedColis.push(colis);
    this.pdf.GeneratePdfVoucher(selectedColis);
  }
  onPageChange(event) {
    this.EventRows = event.rows;
    let pageNumber = event.first / event.rows + 1;

    this.ssetTimeOut(pageNumber);
  }
  filterAll(val, att) {
    if (att == "client") {
      this.client = val;
    }
    if (att == "ean") {
      this.ean = val;
    }
    if (att == "gsm1") {
      this.gsm1 = val;
    }
    if (att == "cod") {
      this.cod = val;
    }
    this.ssetTimeOut(1);
  }

  onStatutChange(event) {
    this.statuts = event.value.map((it) => {
      return it.id;
    });
    this.ssetTimeOut(1);
  }
}
