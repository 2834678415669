import { PickupComponent } from './colis/pickup/pickup.component';
import { ExportDataComponent } from './statistiques/export-data/export-data.component';
import { EnattenteImprimerComponent } from './colis/enattente-imprimer/enattente-imprimer.component';
import { TrackingComponent } from './colis/tracking/tracking.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { DashboardComponent } from './pages/dashboard.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { UnitaireComponent } from './colis/unitaire/unitaire.component'
import { AttenteComponent } from './colis/attente/attente.component'
import { AuthGuardService } from './services/auth-guard.service';
import { StatistiqueComponent } from './statistiques/statistique/statistique.component';
import { ConsultationPaiementComponent } from './paiement/consultation-paiement/consultation-paiement.component';
import { ArchiveColisComponent } from './statistiques/archive-colis/archive-colis.component';
import { ColisPayeeComponent } from './statistiques/colis-payee/colis-payee.component';
import { EchangeColisComponent } from './colis/echange-colis/echange-colis.component';
import { ColisByExcelComponent } from './colis/colis-by-excel/colis-by-excel.component';





@NgModule({
    imports: [
        RouterModule.forRoot([

            { path: '', component: AppLoginComponent },
            {
                path: 'dash', component: AppMainComponent,
                children: [
                    { path: '', component: DashboardComponent },
                    { path: 'unitaire', component: UnitaireComponent, canActivate: [AuthGuardService] },
                    {path: 'import-excel', component: ColisByExcelComponent, canActivate: [AuthGuardService]},
                    { path: 'archive-colis', component: ArchiveColisComponent, canActivate: [AuthGuardService] },
                    { path: 'colis-echange', component: EchangeColisComponent, canActivate: [AuthGuardService] },
                    { path: 'attente', component: AttenteComponent, canActivate: [AuthGuardService] },
                    { path: 'attente-imprimer', component: EnattenteImprimerComponent, canActivate: [AuthGuardService] },
                    { path: 'colis-en-cours', component: StatistiqueComponent, canActivate: [AuthGuardService] },
                    { path: 'export-data', component: ExportDataComponent, canActivate: [AuthGuardService] },
                    { path: 'stat-statut/:id', component: ColisPayeeComponent, canActivate: [AuthGuardService] },
                    { path: 'paiement', component: ConsultationPaiementComponent, canActivate: [AuthGuardService] },
                    { path: 'pickup', component: PickupComponent, canActivate: [AuthGuardService] }
                ],
                canActivate: [AuthGuardService]

            },
            //with Activated Guard


            { path: 'error', component: AppErrorComponent },
            { path: 'accessdenied', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: AppLoginComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
