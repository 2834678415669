import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';



@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppMainComponent) { }

    ngOnInit() {
        this.model = [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/dash'] },
            { label: 'Création Rapide', icon: 'pi pi-fw pi-plus-circle', routerLink: ['unitaire'] },
            {label: 'Import Excel', icon: 'pi pi-fw pi-paperclip', routerLink: ['import-excel']},

            {
                label: 'Colis En Attente', icon: 'pi pi-fw pi-tag', routerLink: ['unitaire'],
                items: [
                    { label: 'En Attente Non Imprimé', icon: 'pi pi-fw pi-tags', routerLink: ['attente'] },
                    { label: 'En Attente Imprimé', icon: 'pi pi-fw pi-tags', routerLink: ['attente-imprimer'] },
                ]
            },
            { label: 'Pickup', icon: 'pi pi-fw pi-upload', routerLink: ['pickup'] },
            {
                label: 'Statistiques', icon: 'pi pi-fw pi-chart-bar', routerLink: ['statistique'],
                items: [
                    { label: 'Statistiques Colis', icon: 'pi pi-fw pi-chart-bar', routerLink: ['colis-en-cours']},
                    { label: 'Export Colis', icon: 'pi pi-fw pi-chart-bar', routerLink: ['export-data'] },
                ]
            },
            {
                label: 'Paiement', icon: 'pi pi-fw pi-dollar', routerLink: ['paiement'],
                items: [
                    { label: 'Consulter Paiements', icon: 'pi pi-fw pi-eye', routerLink: ['paiement'] },
                ]
            },



        ];
    }

    onMenuClick() {
        this.app.menuClick = true;
    }
}
