import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';

import { Statut } from '../../entities/Statut';
import { environment } from 'src/environments/environment';

const BACKEND_DOMAIN = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class StatutsService {
  public statuts:Statut[];

  constructor(private _http: HttpClient) { }


  getStatuts() {
    let params = new HttpParams().set("token",localStorage.getItem("token")); 
    return this._http.get<Statut[]>(this.buildURL('/api/v1/statut')
    ,{params: params})
  }
  

  buildURL(path) {
    return BACKEND_DOMAIN + path;
}


}



