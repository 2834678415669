import { Component, Renderer2 } from '@angular/core';
import { MenuService } from './app.menu.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    animations: [
        trigger('mask-anim', [
            state('void', style({
                opacity: 0
            })),
            state('visible', style({
                opacity: 0.8
            })),
            transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
        ])
    ]
})
export class AppMainComponent {
    menuMobileActive: boolean;

    horizontalMenu: boolean=true;
    menuColorMode = 'light';
    menuColor = 'layout-menu-light';
    themeColor = 'red';
    layoutColor = 'red';
    menuClick: boolean;

    staticMenuActive: boolean;



    sidebarActive: boolean;

    menuHoverActive: boolean;
    ripple: boolean = true;

    inputStyle = 'outlined';

    constructor(public renderer: Renderer2, private menuService: MenuService, private primengConfig: PrimeNGConfig) {}

    ngOnInit() {
    }

    onLayoutClick() {

        if (!this.menuClick) {
            if (this.isHorizontal()) {
                this.menuService.reset();
            }

            if (this.menuMobileActive) {
                this.menuMobileActive = false;
            }


            this.menuHoverActive = false;
        }
        this.menuClick = false;
    }


    
    
    onMenuButtonClick(event) {
        this.menuClick = true;

        if (this.isMobile()) {
            this.menuMobileActive = !this.menuMobileActive;
        }

        event.preventDefault();
    }

    onSidebarClick(event: Event) {
        this.menuClick = true;
    }

    onToggleMenuClick(event: Event) {
        this.staticMenuActive = !this.staticMenuActive;
        event.preventDefault();
    }


    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    isHorizontal() {
        return this.horizontalMenu === true;
    }

   
}
