<p-toast position="top-right"></p-toast>

<div class="p-grid">
  <div class="p-col-12">
    <div class="card">

      <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-4 p-offset-4">
        <a
          pButton
          pRipple
          href="../../../assets/template-excel/template.xlsx"
          type="button"
          label="Telecharger le Modéle Excel"
          class="p-button-warning"
        ></a>
      </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-4 p-offset-4">
          <label for="range">Veuillez selectionner Un Fichier </label>
          <input
            type="file"
            pInputText
            (change)="incomingfile($event)"
            placeholder="Upload file"
            accept=".xlsx"
          />
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-4 p-offset-4">
          <button
            pButton
            pRipple
            (click)="TreatFile()"
            type="button"
            label="Traiter le fichier"
            class="p-button-success"
            [disabled]="!file"
          ></button>
        </div>

        <div class="p-field p-col-12 p-md-4 p-offset-4">
          <button
            pButton
            pRipple
            (click)="CreateListColis()"
            type="button"
            [disabled]="validateColis"
            label="Creation Colis Multiple"
            class="p-button-success"
          ></button>
        </div>

      </div>

      <p-table [value]="list_colis">
        <ng-template pTemplate="header">
          <tr>
            <th>NOM</th>
            <th>GSM</th>
            <th>GOVERNORAT</th>
            <th>ADRESSE</th>
            <th>COD</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-colis>
          <tr>
            <td>{{ colis.client }}</td>
            <td>{{ colis.gsm1 }}</td>
            <td>{{ colis.governorat }}</td>
            <td>{{ colis.adresse }}</td>
            <td>{{ colis.cod }}</td>
          </tr>
        </ng-template>
      </p-table>

      <h3 style="text-align: center">Exception</h3>

      <p-table [value]="list_exception">
        <ng-template pTemplate="header">
          <tr>
            <th>NOM</th>
            <th>GSM</th>
            <th>GOVERNORAT</th>
            <th>ADRESSE</th>
            <th>COD</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-colis>
          <tr>
            <td>{{ colis.client }}</td>
            <td>{{ colis.gsm1 }}</td>
            <td style="background-color: red; color: white">
              >{{ colis.governorat }}
            </td>
            <td>{{ colis.adresse }}</td>
            <td>{{ colis.cod }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
