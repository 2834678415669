<div class="p-grid layout-dashboard">
    
    <div class="p-col-12 p-xl-4 p-md-4">
        <div [routerLink]="['stat-statut', statut_list[11].id]"  style="cursor: pointer;"  class="p-grid p-gutter30 widget-overview-box overview-box-attente">
            <div class="p-col-2 overview-box-icon">
        <i class="dash-icons pi pi-fw pi-chevron-circle-up"> </i>
            </div>
            <div class="p-col-7 overview-box-text">
                <h5>En Attente</h5>
                <span  *ngIf="MONTANT">{{statut_list[11].total}}</span>
            </div>
            <div class="p-col-3 overview-box-status" *ngIf="isDataAvailable">
               {{statut_list[11].count}}
            </div>
        </div>
    </div>
   
   <div class="p-col-12 p-xl-4 p-md-4">
        <div [routerLink]="['stat-statut', statut_list[0].id]"  style="cursor: pointer;"  class="p-grid p-gutter30 widget-overview-box overview-box-encours">
            <div class="p-col-2 overview-box-icon">
        <i class="dash-icons pi pi-fw pi-refresh"> </i>
            </div>
            <div class="p-col-7 overview-box-text">
                <h5>En Cours </h5>
                <span *ngIf="MONTANT">{{statut_list[0].total}}</span>
            </div>
            <div class="p-col-3 overview-box-status" *ngIf="isDataAvailable">
                {{statut_list[0].count}}
            </div>
        </div>
    </div>

     <div class="p-col-12 p-xl-4 p-md-4">
        <div [routerLink]="['stat-statut', statut_list[1].id]"  style="cursor: pointer;"  class="p-grid p-gutter30 widget-overview-box overview-box-livre">
            <div class="p-col-2 overview-box-icon">
        <i class="dash-icons pi pi-fw pi-check-circle"> </i>
            </div>
            <div class="p-col-7 overview-box-text">
                <h5>Colis Livrés</h5>
                <span  *ngIf="MONTANT">{{statut_list[1].total}}</span>
            </div>
            <div class="p-col-3 overview-box-status" *ngIf="isDataAvailable">
                {{statut_list[1].count}}
            </div>
        </div>
    </div>

       <div class="p-col-12 p-xl-4 p-md-4">
        <div [routerLink]="['stat-statut', statut_list[2].id]"  style="cursor: pointer;"  class="p-grid p-gutter30 widget-overview-box overview-box-depot">
            <div class="p-col-2 overview-box-icon">
        <i class="dash-icons pi pi-fw pi-chevron-circle-down"> </i>
            </div>
            <div class="p-col-7 overview-box-text">
                <h5>En Depot</h5>
                <span  *ngIf="MONTANT">{{statut_list[2].total}}</span>
            </div>
            <div class="p-col-3 overview-box-status" *ngIf="isDataAvailable">
                {{statut_list[2].count}}
            </div>
        </div>
    </div>

      <div class="p-col-12 p-xl-4 p-md-4">
        <div [routerLink]="['stat-statut', statut_list[3].id]"  style="cursor: pointer;"  class="p-grid p-gutter30 widget-overview-box overview-box-transit">
            <div class="p-col-2 overview-box-icon">
        <i class="dash-icons pi pi-fw pi-share-alt"> </i>
            </div>
            <div class="p-col-7 overview-box-text">
                <h5>En Transit</h5>
                <span  *ngIf="MONTANT">{{statut_list[3].total}}</span>
            </div>
            <div class="p-col-3 overview-box-status" *ngIf="isDataAvailable">
                {{statut_list[3].count + statut_list[13].count}}
            </div>
        </div>
    </div>

      <div class="p-col-12 p-xl-4 p-md-4">
        <div [routerLink]="['stat-statut', statut_list[4].id]"  style="cursor: pointer;"  class="p-grid p-gutter30 widget-overview-box overview-box-planifie">
            <div class="p-col-2 overview-box-icon">
        <i class="dash-icons pi pi-fw pi-calendar"> </i>
            </div>
            <div class="p-col-7 overview-box-text">
                <h5>Enlevé</h5>
                <span  *ngIf="MONTANT">{{statut_list[4].total}}</span>
            </div>
            <div class="p-col-3 overview-box-status" *ngIf="isDataAvailable">
                {{statut_list[4].count}}
            </div>
        </div>
    </div>

  <div class="p-col-12 p-xl-4 p-md-4">
        <div [routerLink]="['stat-statut', statut_list[8].id]"  style="cursor: pointer;"  class="p-grid p-gutter30 widget-overview-box overview-box-retour">
            <div class="p-col-2 overview-box-icon">
        <i class="dash-icons pi pi-fw pi-calendar"> </i>
            </div>
            <div class="p-col-7 overview-box-text">
                <h5>Prep Retour</h5>
                <span  *ngIf="MONTANT">Voir plus</span>
            </div>
            <div class="p-col-3 overview-box-status" *ngIf="isDataAvailable">
                {{statut_list[8].count}}
            </div>
        </div>
    </div>

      <div class="p-col-12 p-xl-4 p-md-4">
        <div  [routerLink]="['stat-statut', statut_list[5].id]"  style="cursor: pointer;" class="p-grid p-gutter30 widget-overview-box overview-box-exception">
            <div class="p-col-2 overview-box-icon">
        <i class="dash-icons pi pi-exclamation-circle"> </i>
            </div>
            <div class="p-col-7 overview-box-text">
                <h5>Retour</h5>
                <span  *ngIf="MONTANT">Voir plus</span>
            </div>
            <div class="p-col-3 overview-box-status" *ngIf="isDataAvailable">
                {{statut_list[5].count}}
            </div>
        </div>
    </div>

      <div class="p-col-12 p-xl-4 p-md-4">
        <div [routerLink]="['stat-statut', statut_list[7].id]"  style="cursor: pointer;"  class="p-grid p-gutter30 widget-overview-box overview-box-paye">
            <div class="p-col-2 overview-box-icon">
        <i class="dash-icons pi pi-money-bill"> </i>
            </div>
            <div class="p-col-7 overview-box-text">
                <h5>Colis Payés</h5>
                <span  *ngIf="MONTANT">Voir plus</span>
            </div>
            <div class="p-col-3 overview-box-status" *ngIf="isDataAvailable">
                {{statut_list[7].count}}
            </div>
        </div>
    </div>



</div>
