import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BreadcrumbService } from '../breadcrumb.service';
import { ColisService } from '../services/colis.service';
import { Statut } from '../../entities/Statut';
import { StatutsService } from '../services/statuts.service';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientsService } from '../services/clients.service';


@Component({
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    
    MONTANT = environment.show_Montant;
    statut_list: Statut[] = [];
    response: any;
    isDataAvailable:boolean = false;

    constructor(private clientService: ClientsService,private _statuts: StatutsService, private _auth: ColisService, private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }




    ngOnInit() {
        this._statuts.getStatuts().subscribe(
            (response: any) => {
                response.forEach(element => {
                    var statut = new Statut();
                    statut.id = element.id;
                    statut.designation = element.designation;
                    statut.color = element.color;
                    statut.count = element.count || 0;
                    this.statut_list.push(statut);
                });
                this._auth.getCountStatut().subscribe(
                    (res: any) => {
                        for (let element in res) {
                            this.statut_list[(+element-1)].count = res[element][0];
                            this.statut_list[(+element-1)].total = res[element][1];
                        }
                    });
                this.isDataAvailable = true
                localStorage.setItem('statutsList', JSON.stringify(this.statut_list));
            });


            this.clientService.info().subscribe(
                info => {
                    localStorage.setItem('info', JSON.stringify(info));
                }
              );


    }
}
