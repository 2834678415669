import {Component, Inject, forwardRef} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
			<div class="logo-text">
				<div class="text">
					<h1>ASSISTANCE {{APPNAME}}</h1>
					<span></span>
				</div>
			</div>
	
        </div>
    `
})
export class AppFooterComponent {
	APPNAME = environment.appname;

}
