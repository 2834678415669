import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';



import { Colis } from '../../entities/Colis';
import { Statut } from 'src/entities/Statut';
import { User } from 'src/entities/User';
import { Recu } from 'src/entities/Recu';
import { environment } from 'src/environments/environment';
const optionRequete = {

};
const BACKEND_DOMAIN = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class PaiementsService {

  constructor(private _http: HttpClient) { }



getAllRecuByuser() {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get<Recu[]>(this.buildURL('/api/admin/paiement/user/',localStorage.getItem('User_id'))
  ,{params: params});

}

buildURL(path,user?) {
  return BACKEND_DOMAIN + path+user;
}

getKeyStatut(param) {
  var stat:Statut[];
  stat=JSON.parse(localStorage.getItem("statutsList"));
  for(let element of stat){
    if (element.designation.toUpperCase() === param.toUpperCase()) {
    return element.id;
    }  
  }
}




}
