import { Component, OnInit,ViewChild } from '@angular/core';
import { Colis } from 'src/entities/Colis';
import { BreadcrumbService } from '../../breadcrumb.service';
import {ColisService} from '../../services/colis.service'
import { Table } from 'primeng/table';
import { Statut } from 'src/entities/Statut';
import { PrimeNGConfig } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import {Pdf} from '../../../entities/pdf'
import * as jsPDF from 'jspdf'
import { AppConstants } from 'src/app/AppConstants';

@Component({
  selector: 'app-echange-colis',
  templateUrl: './echange-colis.component.html',
  styleUrls: ['./echange-colis.component.scss']
})
export class EchangeColisComponent implements OnInit {
  stat:Statut[]=JSON.parse(localStorage.getItem("statutsList"));
  colis_list: Colis[];
  governotat: any[];
  loading: boolean = true;
  pdf = new Pdf();
  colis_track:any=[];
  displayModal: boolean;
  states=AppConstants.governorats;


  @ViewChild('dt') table: Table;


  constructor(private _auth: ColisService,private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this._auth.getAllColisEchange().subscribe(   
    data => {
      this.colis_list = data
      this.loading = false;
    })
   
  }


  onDateSelect(value) {
    this.table.filter(this.formatDate(value), 'date', 'equals')
}
formatDate(date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
      month = '0' + month;
  }
  if (day < 10) {
      day = '0' + day;
  }
  return date.getFullYear() + '-' + month + '-' + day;
}

onStatutChange(event) {
  var search:any[]=[];
  event.value.forEach(element => {
    search.push(element.id);
  });
  this.table.filter(search, 'last_statut', 'in')
}

getColisTrack(colis){
  this.colis_track=[];
  this._auth.getColisTrack(colis).subscribe(   
    (response: any) => {
      response.forEach(element => {
        var track:any=[];
        track['statut']=this.stat[(+element.statut_id-1)].designation;
        track['date']=element.created_at;
        if(element.reference!='NULL'){
          track['chargement']=element.reference;
        }
        this.colis_track.push(track);
      });
      this.displayModal=true;
});
}

onGovChange(event) {
  var search:any[]=[];
  event.value.forEach(element => {
    search.push(element.name);
  });
  this.table.filter(search, 'governorat', 'in')
}

showModalDialog() {
  this.displayModal = true;
}

GeneratePdfVouchers(colis:Colis) {
  var selectedColis:Colis[]=[];
  selectedColis.push(colis);
  this.pdf.GeneratePdfVoucher(selectedColis);
  }

}
