<div class="card">
    <p-table #dt [value]="List_recu" dataKey="id" [loading]="loading" [rows]="50" [rowHover]="true"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100,250]"
        styleClass="p-datatable-responsive-demo" [paginator]="true" [sortOrder]="-1"
        currentPageReportTemplate="De {first} a {last} des {totalRecords} Paiements" [filterDelay]="0">
        <ng-template pTemplate="caption">
            <div class="table-header">
                Gestionnaire des Reçus
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Date</th>
                <th>Nbre de Colis</th>
                <th>Total COD</th>
                <th>Frais Transport</th>
                <th>Paiement Net</th>
                <th>Actions</th>
            </tr>
            <tr>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'created_at', 'contains')"
                        placeholder="Date" class="p-column-filter">
                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'id', 'contains')"
                        placeholder="Numero" class="p-column-filter">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'nbre_colis', 'contains')"
                        placeholder="Nbre de Colis" class="p-column-filter">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'total_cod', 'contains')"
                        placeholder="Total COD" class="p-column-filter">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'frais', 'contains')"
                        placeholder="Frais Transport" class="p-column-filter">
                </th>
                <th>Paiement</th>
                <th></th>
                <th></th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-recu>
            <tr>
                <td>
                    {{recu.created_at | date: 'MMM d,y'}}
                </td>
                <td>
                    {{recu.id}}
                </td>
                <td>
                    {{recu.nbre_colis}}
                </td>
                <td>
                    {{recu.total_cod}}
                </td>
                <td>
                    {{recu.frais}}
                </td>
                <td>
                    <b>{{recu.total_cod - recu.frais}}</b>
                </td>
                <td>
                    <b> <span *ngIf="recu.payee==1; else notPaye">Payé</span> 
                        <ng-template  #notPaye>
                        <span>Non Payée</span>
                        </ng-template>
                    </b>
                </td>
                <td>
                    <div class="p-d-md-none p-fluid"><button pButton pRipple icon="pi pi-file-excel"
                            style="margin: 0 5px;" class="p-button-success p-button-rounded"
                            (click)="exportExcel(recu)"></button></div>
                    <div class="p-d-none p-d-md-inline-flex">
                        <button pButton pRipple icon="pi pi-file-excel" label="Excel" style="margin: 0 5px;"
                            class="p-button-success p-button-rounded" (click)="exportExcel(recu)"></button>
                    </div>
                    <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-warning"
                    (click)="GeneratePdfList(recu)"></button>

                </td>

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6">Aucun Ramassage trouvé</td>
            </tr>
        </ng-template>
    </p-table>
</div>