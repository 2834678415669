import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {AppMainComponent} from './app.main.component';
import { TokenService } from './services/token.service';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="layout-topbar">
			<div class="layout-topbar-wrapper">
                <div class="layout-topbar-left">
					<div class="layout-topbar-logo-wrapper">
						<a href="#" class="layout-topbar-logo">
							<img src={{APPLOGO}} alt="mirage-layout" />
							<!--<span class="app-name">{{APPNAME}}</span> -->
						</a>
					</div>

					<a href="#" class="sidebar-menu-button" (click)="app.onMenuButtonClick($event)">
						<i class="pi pi-bars"></i>
					</a>
                </div>
                <div class="layout-topbar-right fadeInDown">
                <ul class="layout-topbar-actions">
                <li #message class="topbar-item">
							<a href="#" (click)="Disconect()">
								<i class="topbar-icon pi pi-sign-out"></i> 
							</a>
				
						</li>
                </ul>

                </div>
                
            </div>
        </div>
    `
})
export class AppTopBarComponent {
    APPNAME = environment.appname;
    APPLOGO = environment.logo;
    activeItem: number;

    constructor(public app: AppMainComponent,private _token:TokenService,private router: Router) {}

    Disconect(){   
    this._token.remove();
   this.router.navigateByUrl('/login');
    }


}
