import { Pickup } from './../../entities/Pickup';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';



import { Colis } from '../../entities/Colis';
import { Statut } from 'src/entities/Statut';
import { Recu } from 'src/entities/Recu';
import { environment } from 'src/environments/environment';
const optionRequete = {
  headers: new HttpHeaders({ 
  })
};
const BACKEND_DOMAIN = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ColisService {
  public colis:Colis[];

  constructor(private _http: HttpClient) { }



Create(colis: Colis): any {
    return this._http.post(this.buildURL('/api/v1/colis/create',''), {
      token:localStorage.getItem("token"),
      ean : colis.ean,
      cod : colis.cod,
      user_id : colis.user_id,
      client : colis.client,
      adresse : colis.adresse,
      governorat : colis.governorat,
      gsm1 : colis.gsm1,
      gsm2 : colis.gsm2,
      designation : colis.designation,
      nbre : colis.nbre,
     last_depot:colis.last_depot,
      multiple:colis.multiple,
      remarque : colis.remarque,
      echange : colis.echange,
      decharge : colis.decharge,
      last_statut : colis.last_statut,
      old_ean : colis.old_ean,
    },optionRequete).toPromise();
}

AddTopickup(chargement: Pickup,statut :string): any {
  return this._http.post(this.buildURL('/api/user/pickup/create',''), {
    token:localStorage.getItem("token"),
    type : chargement.type,
    reference : chargement.reference,
    user_id : chargement.user_id,
    remarque : chargement.remarque,
    total_colis:chargement.total_colis,
    list_colis:chargement.list_colis,
    id_statut:this.getKeyStatut(statut)
  },optionRequete).toPromise();
}


getAllEnlevementsByUser(id:number) {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get<Pickup[]>(this.buildURL('/api/user/pickup/byuser/',id)
  ,{params: params});
}

Delete(chargement: Pickup,statut :string): any {
  return this._http.post(this.buildURL('/api/user/pickup/destroy',''), {
    token:localStorage.getItem("token"),
    user_id : chargement.user_id,
    reference : chargement.reference,
    id_statut:this.getKeyStatut(statut)
  },optionRequete).toPromise();
}




getEnlevementColisByid(id:number) {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get<any[]>(this.buildURL('/api/user/pickup/colis/',id)
  ,{params: params});
}


Update(colis:Colis):any {
  return this._http.post(this.buildURL('/api/v1/colis/update/',colis.id), {
    token:localStorage.getItem("token"),
    cod : colis.cod,
    client : colis.client,
    adresse : colis.adresse,
    gsm1 : colis.gsm1,
    gsm2 : colis.gsm2,
    designation : colis.designation,
    nbre : colis.nbre,
    remarque : colis.remarque,
    echange : colis.echange,
    decharge : colis.decharge,
  },optionRequete).toPromise();
}

getAllArchiveColis() {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get<Colis[]>(this.buildURL('/api/v1/colis/archive_user/',localStorage.getItem('User_id'))
  ,{params: params});
}

getKeyStatut(param) {
  var stat:Statut[];
  stat=JSON.parse(localStorage.getItem("statutsList"));
  for(let element of stat){
    if (element.designation.toUpperCase() === param.toUpperCase()) {
    return element.id;
    }  
  }
}

getAllColis(k, pageNumber, cod, client, id, governorat, adresse, gsm1, gsm2, remarque, ean, from, to,
  echange, statuts
) {
  
  return this._http.post<any>(this.buildURL('/api/v1/colis/user/'+ k, ''), {
    token: localStorage.getItem("token"),
    user_id:localStorage.getItem('User_id'),
    page: pageNumber,
    cod: cod,
    nom: client,
    id: id,
    governorat: governorat,
    adresse: adresse,
    gsm1: gsm1,
    gsm2: gsm2,
    remarque: remarque,
    ean: ean,
    from: from,
    to: to,
    echange: echange,
    statuts: statuts
  }
    , optionRequete);
}
getAllColisByRecu(recu:Recu) {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get<Colis[]>(this.buildURL('/api/v1/colis/recu/',recu.id)
  ,{params: params});
}

getAllColisPayee() {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get<Colis[]>(this.buildURL('/api/v1/colis/user/payee/',localStorage.getItem('User_id'))
  ,{params: params});
}
getAllColisEchange() {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get<Colis[]>(this.buildURL('/api/v1/colis/user/echange/',localStorage.getItem('User_id'))
  ,{params: params});
}
getAllColisEnCours() {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get<Colis[]>(this.buildURL('/api/v1/colis/user/',localStorage.getItem('User_id'))
  ,{params: params});
}
getHistoryByGsm(gsm) {
  let params = new HttpParams().set("token",localStorage.getItem("token")).set("gsm1",gsm ); 
  return this._http.get<Colis[]>(this.buildURL('/api/v1/colis/history/',localStorage.getItem('User_id'))
  ,{params: params});
}


getAttente() {
    let params = new HttpParams().set("token",localStorage.getItem("token")).set("last_statut", this.getKeyStatut('en Attente')); 
    return this._http.get<Colis[]>(this.buildURL('/api/v1/colis/attente/',localStorage.getItem('User_id'))
    ,{params: params});
}

getCountStatut () {
    let params = new HttpParams().set("token",localStorage.getItem("token")); 
    return this._http.get(this.buildURL('/api/v1/colis/statut/',localStorage.getItem('User_id'))
    ,{params: params});
}


getColisForExportData(date_begin:string,date_end:string,statut): any {

   return this._http.post(this.buildURL('/api/v1/colis/search',''), {
     token:localStorage.getItem("token"),
     user_id:localStorage.getItem('User_id'),
     date_begin:date_begin,
     date_end:date_end,
     statuts:statut
   });
 }

getColisTrackArchive(selectedColis) {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get(this.buildURL('/api/v1/statut/archive/',selectedColis)
  ,{params: params});
}
getColisTrack(selectedColis) {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get(this.buildURL('/api/v1/statut/',selectedColis)
  ,{params: params});
}


deleteColis(selectedProducts) {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.delete(this.buildURL('/api/v1/colis/',selectedProducts)
  ,{params: params});
}

SetImprimer(id :number): any {
  return this._http.post(this.buildURL('/api/v1/colis/imprimer/',id), {
    token:localStorage.getItem("token"),
  },optionRequete).toPromise();
}

getColisByean(ean:number) {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get<Colis[]>(this.buildURL('/api/v1/colis/ean/byuser/',ean)
  ,{params: params});
}

getColisTrackByean(selectedColis) {
  let params = new HttpParams().set("token",localStorage.getItem("token")); 
  return this._http.get(this.buildURL('/api/v1/statut/byuser/track/',selectedColis)
  ,{params: params});
}

buildURL(path,user?) {
    return BACKEND_DOMAIN + path+user;
}
 
}
