import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  apiRequest: HttpRequest<any>;

  constructor(private injector: Injector, private _router: Router) { }

  intercept(request, next) {
    const tokenService = this.injector.get(TokenService);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));




    if (JSON.stringify(request).includes('auth')) {
      return next.handle(request);
    } else if (tokenService.get() && currentUser) {
      this.apiRequest = request.clone({ setHeaders: { Authorization: tokenService.get() } });
      return next.handle(request).pipe(catchError(err => {
        if (err.status === 401) {
            // auto logout if 401 response returned from api
            tokenService.remove();
      this._router.navigate(['/login']);
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
    }))
    } else {
      this._router.navigate(['/login']);

    }


  }


}