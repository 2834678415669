export class Pickup {
    public id: string;
    public type: string;
    public reference: string;
    public livreur_id: number;
    public user_id: number;
    public remarque: string;
    public total_colis: number;
    public list_colis : string;
    public created_at:string


    constructor(id?, type?, reference?,livreur_id?,user_id?,remarque?,total_colis?,list_colis?,created_at?) {
        this.id = id;
        this.type = type;
        this.reference = reference;
        this.livreur_id = livreur_id;
        this.user_id = user_id;
        this.remarque = remarque;
        this.total_colis = total_colis;
        this.list_colis = list_colis;
        this.created_at = created_at;
      }
}

