import { Component, OnInit, ViewChild } from '@angular/core';
import {FormsModule, FormGroup,FormControl} from '@angular/forms';
import {BreadcrumbService} from '../../breadcrumb.service';
import {Colis } from '../../../entities/Colis';
import {ColisService} from '../../services/colis.service'
import {MessageService} from 'primeng/api';
import { Statut } from 'src/entities/Statut';
import { AppConstants } from 'src/app/AppConstants';



@Component({
  selector: 'app-unitaire',
  templateUrl: './unitaire.component.html',
  styleUrls: ['./unitaire.component.css'],
  providers: [MessageService]
})
export class UnitaireComponent implements OnInit {
  @ViewChild('f') form: any;
  public id_user = +localStorage.getItem("User_id");
  public colis: Colis;
  HistoriqueGsm:Colis[]=[];
  selectedState: any = null;
  states=AppConstants.governorats;
  stat:Statut[]=JSON.parse(localStorage.getItem("statutsList"));



  constructor(
    private breadcrumbService: BreadcrumbService,
    private _auth: ColisService,
    private messageService: MessageService
    ) {
    this.breadcrumbService.setItems([
      { label: 'Expedition' },
      { label: 'Creation Rapide', routerLink: ['unitaire'] }
  ]);
   }
   
  ngOnInit(): void {
    this.colis = new Colis();
    this.colis.echange=false;
    this.colis.decharge=false;
    this.colis.fragile=false;
    this.colis.designation='Produit';
    this.colis.nbre=1;
  }

  generateEan() {
    var ean = new String( "1407" ); 
    var id_on_ean = new String(this.id_user); 
    var randon = Math.floor((Math.random() * 999999) + 1);
    var str3 = ean.concat(id_on_ean.toString());
    var str4 = str3.concat(randon.toString());
    return str4;
  }

  getKeyStatut(param) {
    var stat:Statut[];
    stat=JSON.parse(localStorage.getItem("statutsList"));
    for(let element of stat){
      if (element.designation.toUpperCase() === param.toUpperCase()) {
      return +element.id;
      }  
    }
  }


  async creer() {
  this.colis.user_id=this.id_user; 
  this.colis.governorat=this.selectedState['name'];
  var enAttenteStatut=this.getKeyStatut('En Attente');
  this.colis.last_statut=+enAttenteStatut;
  this.colis.last_depot=1;
  this.colis.ean=+this.generateEan();
    try {
      const success = await this._auth.Create(this.colis);
      this.form.reset({designation :'Produit', echange:false,nbre:'1',decharge:false,fragile:false});
      this.messageService.add({severity:'success', summary: 'Success', detail: 'Votre Colis a étè ajouté avec succés'});
    } catch (error) {
      console.error(error);
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Erreur ! Verifier vos informations'});
    }
  }

 
  VerifyNumber() {
    this.HistoriqueGsm=[];
    this._auth.getHistoryByGsm(this.colis.gsm1).subscribe(data => {
      this.HistoriqueGsm =data;
    })
  }


}