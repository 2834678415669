import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ColisService } from 'src/app/services/colis.service';
import { Colis } from 'src/entities/Colis';
import { Statut } from 'src/entities/Statut';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.css'],
  providers: [DatePipe]

})
export class ExportDataComponent implements OnInit {
  
  list_colis: Colis[] = [];
  rangeDates: Date[];
  stat: Statut[] = JSON.parse(localStorage.getItem("statutsList"));
  selectedState: any = '';
  TotalCod: number = 0;



  constructor(private _colis: ColisService, private datePipe: DatePipe) { }

  ngOnInit(): void {
  }


  getColisForExportData() {
    this.TotalCod=0;
    if (this.rangeDates) {
      var date1 = this.datePipe.transform(this.rangeDates[0], "yyyy-MM-dd");
      var date2 = this.datePipe.transform(this.rangeDates[1], "yyyy-MM-dd");
    }

    var statut = '';
    if (this.selectedState.id) {
      statut = this.selectedState.id;
    }

    this._colis.getColisForExportData(date1, date2, statut).subscribe(
      data => {
        this.list_colis = data;
        this.TotalCod = this.list_colis.map(a => a.cod).reduce(function (a, b) {
          return +a + +b;
        });
      }
    )
  }

  exportExcel() {
   
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.list_colis);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "products");
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }






}


