<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <div class="p-fluid p-grid p-formgrid">

            <div class="p-col-12 p-md-4">
                <label for="range">Dates : </label>
                <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" inputId="range">
                </p-calendar>
            </div>
            <div class="p-col-12 p-md-3">
                <label for="state">Statut</label>
                <p-dropdown name="gov" optionLabel="designation"  [options]="stat" [(ngModel)]="selectedState" placeholder="Select" ></p-dropdown>               
            </div>
            <div class="p-col-12 p-md-1" [style]="{'margin-top': '15px'}">
                <button pButton pRipple (click)="getColisForExportData()" type="button" label="Recherche"class="p-button-success" 
                ></button>
            </div>
 
            </div>
        </div>

            <div class="card">

            <p-table [value]="list_colis">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <h5 class="p-m-0">Recherche Colis</h5>
                            <button type="button" label="Exporter Excel" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            class="p-button-success p-ml-auto p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>EAN</th>
                        <th>NOM</th>
                        <th>GSM </th>
                        <th>GOVERNORAT</th>
                        <th>ADRESSE</th>
                        <th>STATUT</th>
                        <th>COD</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-colis>
                    <tr>
                        <td>{{colis.ean}}</td>
                        <td>{{colis.client}}</td>
                        <td>{{colis.gsm1}}</td>
                        <td>{{colis.governorat}}</td>
                        <td>{{colis.adresse}}</td>
                        <td>
                            <span class="customer-badge"
                            [style]="'background:'+ stat[+(colis.last_statut)-1].color">{{stat[+(colis.last_statut)-1].designation}}
                        </span>
                        </td>
                        <td>{{colis.cod}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Vous avez {{list_colis ? list_colis.length : 0 }} colis trouvés. <b><span style="font-size: 15px;"> {{TotalCod}} DT</span></b>
                    </div>

                </ng-template>
            </p-table>

        </div>
    </div>
</div>