import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { ColisService } from 'src/app/services/colis.service';
import { Colis } from 'src/entities/Colis';
import {MessageService} from 'primeng/api';
import { AppConstants } from 'src/app/AppConstants';
import { Statut } from 'src/entities/Statut';


@Component({
  selector: 'app-colis-by-excel',
  templateUrl: './colis-by-excel.component.html',
  styleUrls: ['./colis-by-excel.component.css'],
  providers: [MessageService]
})
export class ColisByExcelComponent implements OnInit {
  allColisList : Colis[]=[];
  list_colis:Colis[]=[]
  colis: Colis;
  validateColis:boolean=true;
  uploadedFiles: string[] = [];
  arrayBuffer:any;
  file:File;
  ArrayTotreat:any[]=[];
  list_exception: Colis[]=[];
  states=AppConstants.governorats;
  public id_user = +localStorage.getItem("User_id");




  constructor(private messageService: MessageService,private _colis: ColisService) { }

  ngOnInit(): void {
  }




  incomingfile(event) 
  {
  this.file= event.target.files[0]; 
  }

  private async Upload() {
    let fileReader = new FileReader();
      fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {type:"binary"});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          this.ArrayTotreat= XLSX.utils.sheet_to_json(worksheet,{raw:true});
      }
      fileReader.readAsArrayBuffer(this.file);

      
}

async TreatFile(){

  this.Upload();
  this.ArrayTotreat.forEach(element => {
    var colis:Colis=new Colis;
    colis.decharge=false;
    colis.nbre=1;
    colis.user_id=this.id_user; 
    var enAttenteStatut=this.getKeyStatut('En Attente');
    colis.last_statut=+enAttenteStatut;
    colis.client=element.client;
    colis.gsm1=element.gsm1;
    colis.gsm2=element.gsm2;
    colis.adresse=element.adresse;
    colis.governorat=element.governorat;
    colis.designation=element.designation;
    colis.remarque=element.remarque;
    colis.echange=element.echange;
    colis.cod=element.cod;

    if(this.states.find(gov => gov.name==element.governorat))
    {
      this.list_colis.push(colis);
    } else {
      this.list_exception.push(colis);
    }

 });
  if(this.list_colis.length !== 0) 
  {
     this.validateColis=false;
  }
}

async CreateListColis(){

  this.list_colis.forEach(async element => {  
    try {
      const success = await this._colis.Create(element);
      if(!success){
        this.list_exception.push(element);
      }
    } catch (error) {
      console.error(error);
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Erreur ! Verifier vos informations'});
    }
  });
  this.messageService.add({severity:'success', summary: 'Success', detail: 'Colis a étè ajouté avec succés'});

  this.list_colis=[];
  this.validateColis=true;
  this.ArrayTotreat=[];
}






getKeyStatut(param) {
  var stat:Statut[];
  stat=JSON.parse(localStorage.getItem("statutsList"));
  for(let element of stat){
    if (element.designation.toUpperCase() === param.toUpperCase()) {
    return +element.id;
    }  
  }
}
}
