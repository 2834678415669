export class AppConstants {
    public static get governorats()
    { 
        return [
            {name: 'Tunis'},
            {name: 'Sousse'},
            {name: 'Nabeul'},
            {name: 'Ariana'},
            {name: 'Manouba'},
            {name: 'Monastir'},
            {name: 'Mahdia'},
            {name: 'Ben Arous'},
            {name: 'Bizerte'},
            {name: 'Kairouan'},
            {name: 'Sfax'},
            {name: 'Zaghouan'},
            {name: 'Beja'},
            {name: 'Gabes'},
            {name: 'Gafsa'},
            {name: 'Jendouba'},
            {name: 'Kasserine'},
            {name: 'Kebili'},
            {name: 'Kef'},
            {name: 'Medenine'},
            {name: 'Sidi Bouzid'},
            {name: 'Siliana'},
            {name: 'Tataouine'},
            {name: 'Tozeur'},
        ]; 
}

public static get chargements_type()
    { 
        return [
        {name: 'Livraison'},
        {name: 'Retour'}
    ]; 
}





}