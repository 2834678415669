import { Injectable } from '@angular/core';
import { User } from '../../entities/User';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const optionRequete = {
  headers: new HttpHeaders({ 
    'X-Requested-With'  : 'XMLHttpRequest',
  })
};

const BACKEND_DOMAIN = environment.baseUrl;

@Injectable()
export class AuthService {

  constructor(private _http: HttpClient) { }


  login(user: User): any {
    return this._http.post(this.buildURL('/auth/login'), {
      email: user.email,
      password: user.password
    },optionRequete).toPromise();
  }

  buildURL(path) {
    return BACKEND_DOMAIN + path;
  }
}