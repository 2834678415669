import { Component,OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { User } from '../../entities/User';
import { TokenService } from '../services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit {
  public model: User;
  dark: boolean=false;
  checked: boolean;
  APPNAME = environment.appname;
  APPLOGO = environment.logo;


constructor(private _auth: AuthService,
  private _router: Router,
  private _token: TokenService,
  ) {}

  ngOnInit() {
    this.model = new User();
  }

  async login() {
    try {
      const success = await this._auth.login(this.model);
      this._token.handle(success['access_token']);
      localStorage.setItem('currentUser', JSON.stringify(this.model));
      localStorage.setItem('User_id', JSON.stringify(success['user_id']));
      localStorage.setItem('User_informations', JSON.stringify(success));
      this._router.navigate(['/dash']);
      console.log(success);
    } catch (error) {
      console.error(error);
      alert('Bad credentials.');
    }
  }

}
