<div class="layout-wrapper" (click)="onLayoutClick()"
     [ngClass]="{'layout-horizontal': horizontalMenu,
                'layout-wrapper-static': staticMenuActive,
                'p-input-filled': inputStyle === 'filled',
                'layout-sidebar-mobile-active': menuMobileActive,
                'p-ripple-disabled': !ripple}" [class]="menuColor">

    <app-menu></app-menu>

    <div class="layout-main">

        <app-topbar></app-topbar>

        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>


        <div class="layout-main-mask"></div>
    </div>
</div>
